$anime-time: 8s;

$box-height: 175px;
$box-width: 325px;
$clip-distance: .05;
$clip-size-width: $box-width * (1 + $clip-distance * 2);
$clip-size-height: $box-height * (1 + $clip-distance * 4);
$path-width: 2px;

$main-color: #a3b2b3;

%full-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bb {
  @extend %full-fill;
  width: $box-width;
  height: $box-height;
  margin: auto;
  color: $main-color;
  box-shadow: inset 0 0 0 1px rgba($main-color, .5);
  display: flex;
  flex-flow: column nowrap;

  &::before,
  &::after {
    @extend %full-fill;
    content: '';
    z-index: 99;
    margin: -1 * $clip-distance * 100%;
    box-shadow: inset 0 0 0 $path-width;
    animation: clipMe $anime-time linear infinite;
  }

  &::before {
    animation-delay: $anime-time * -.5;
  }
}

.bb h1 {
  -webkit-transition: all 1s ease 0s;
}

@keyframes clipMe {
  0%, 100% {clip: rect(0px, $clip-size-width, $path-width, 0px); }
  25% {clip: rect(0px, $path-width, $clip-size-height, 0px); }
  50% {clip: rect($clip-size-height - (4 * $path-width), $clip-size-width, $clip-size-height + (2* $path-width), 0px); }
  75% {clip: rect(0px, $clip-size-width, $clip-size-height + (2 * $path-width), $clip-size-width - (2 * $path-width)); }
}

button {
  position: absolute;
  border: black 5px;
  background: none;
  color: white;
  width: 325px;
  height: 50px;
  font-size: 20px;
  outline: none;
  z-index: 10;
  background: $main-color;
}

@keyframes load {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
}

body, html {
  height: 100%;
  margin: 0;
  font: 400 15px/1.8 "Lato", sans-serif;
  color: #777;
}

h2 {
  color: #fffff7;
  margin: 10px 20%;
}

h3 {
  margin: 0 0;
}

h1 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 20px "Lato", sans-serif;
  color: #111;
  text-align: center;
  margin: 0;
  text-emphasis: black;
}

p {
  padding-top: 0;
  margin: auto;
  text-align: center;
  line-height: 15px;
  max-width: 750px;
}

.portal {
  min-height: 100%;
  width: 100%;
  margin: 0;
  opacity: 1;
  background-color: black;
}

.banner {
  height: 100%;
  width: 100%;
  margin: 0;
  opacity: 1;
  background-image: url("Copy of IMG_3350.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: fadein 2s ease-in-out;
}

@keyframes fadein {
  from {
    height: 0px;
  }
  to   {
    height: 100%;
  }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.description {
  background-color: #fffff7;
  position: relative;
  background-attachment: fixed;
  padding: 50px 80px;
  text-align: center;
}

.description .text {
  max-width: 850px;
  margin: auto auto;
}

.second {
  width: 100%;
  height: 200px;
  background-image: url("P8086774.JPG");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.third {
  width: 100%;
  height: 200px;
  background-image: url("UBC-vancouver-campus.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.caption {
  position: absolute;
  left: 0;
  top: 40%;
  width: 100%;
  text-align: center;
  color: #000;
}

.caption span.border {
  background-color: #111;
  color: #fffff7;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
  text-transform: uppercase;
}

a:link a:visited {
  color: darkseagreen;
}