.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
}

p {
  white-space: pre-wrap;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

table {
  margin: 0 auto;
}

h1 {
  margin: 0;
}

footer {
  bottom: 0;
  width: 100%;
  background-color: darkseagreen;
  height: 100px;
  z-index: -100;
  color: #fffff7;
  padding-top: 100px;
}

@media only screen and (min-width: 768px) {
  footer { position: fixed; }
}

footer h1 {
  color: #fffff7;
}

.final {
  margin-bottom: 150px;
}

.single-container {
  margin-bottom: 150px;
  background-color: #fffff7;
}
