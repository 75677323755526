body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height:100%;
}

html {
  height: 100%;
}

span {
  text-align: center;
}

#root {
  margin: 0 auto;
  height:100%;
}

.mainContainer {
  height: 100%;
}
