#navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0 0px;
  overflow: hidden;
  background-color: #111;
}

#navbar li {
  float: left;
}

#navbar li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
}

#navbar li a:hover {
  background-color: darkseagreen;
}
